<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";

import {mapGetters} from "vuex";


export default {
  page: {
    title: "Account",
    meta: [{ name: "description", content: appConfig.description }]
  },
  computed: {
    ...mapGetters({
      getSubscriberStatus: 'getSubscriberStatus',
      getServers: 'getServers',
      getAccountId: 'getAccountId'
    }),
  },
  components: {
    Layout,
    AccountCore: () => import('@/components/lazy/account/AccountCore.vue'),
  },
  validations: {

  },
  methods: {

  },
  created() {
    if(!this.getSubscriberStatus()) {
      this.redacted = true;
    }
  },
  data() {
    return {
      redacted: false
    }
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <div class="page-title-right bv-d-xs-down-none">
        <ol class="breadcrumb m-0">
        <li class="breadcrumb-item">
          <router-link to="/">
            CFTools Cloud
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span aria-current="location">
            Account
          </span>
        </li>
      </ol>
      </div>
    </div>
    <AccountCore></AccountCore>
  </Layout>
</template>